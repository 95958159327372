/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.no-break-line {
  white-space: nowrap;
  padding: 5px;
}

.new-model-component {
  padding-top: 30px;
  padding-bottom: 60px;

  margin-left: 60px;
  margin-right: 60px;
}

h1 {
  margin-bottom: 30px;
}

.spacer {
  margin-top: 30px;
}

.main-header {
  z-index: 1 !important;
}

.ngx-mat-timepicker form {
  font-size: 16px;
}
@media only screen and (max-width: 575px) {
  body {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
  }

  .new-model-component {
    padding-top: 30px;
    padding-bottom: 60px;

    margin-left: 5px;
    margin-right: 5px;
  }

  .form-component {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mat-elevation-z8 th, .mat-elevation-z8 td {
    font-size: 5px;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 6px !important;
  }

  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 6px !important;
  }

  mat-form-field {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  body {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
  }

  .new-model-component {
    padding-top: 30px;
    padding-bottom: 60px;

    margin-left: 5px;
    margin-right: 5px;
  }

  .form-component {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mat-elevation-z8 th, .mat-elevation-z8 td {
    font-size: 8px;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 6px !important;
  }

  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 6px !important;
  }

  mat-form-field {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-size: cover;
  }

  .new-model-component {
    padding-top: 30px;
    padding-bottom: 60px;

    margin-left: 5px;
    margin-right: 5px;
  }

  .form-component {
    margin-left: 30px;
    margin-right: 30px;
  }

  .mat-elevation-z8 th, .mat-elevation-z8 td {
    font-size: 10px;
  }

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 6px !important;
  }

  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 6px !important;
  }

  mat-form-field {
    width: 40%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  mat-form-field {
    width: 40%;
  }
}

@media only screen and (min-width: 1200px) {
  mat-form-field {
    width: 30%;
  }
}

#timeline-clone-kis svg + div {
  display: none !important;
}

#timeline-kis rect + text {
  /*display: none;*/
}



#timeline-clone-közép svg + div {
  display: none !important;
}

#timeline-közép rect + text {
  /*display: none;*/
}



#timeline-clone-egyterű svg + div {
  display: none !important;
}

#timeline-egyterű rect + text {
  /*display: none;*/
}



#timeline-clone-mikrobusz svg + div {
  display: none !important;
}

#timeline-mikrobusz rect + text {
  /*display: none;*/
}



#timeline-clone-teherautó svg + div {
  display: none !important;
}

#timeline-teherautó rect + text {
  /*display: none;*/
}

@media only screen and (max-width:769px)
{
  input.mat-input-element {
    font-size:23px !important;
  }

  .ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-form-field{
    width: 40px !important;
    max-width: 40px !important;
  }
}

/*#timeline svg g:last-child{*/
/*  display: none !important;*/
/*}*/
/*#timeline svg rect:last-child{*/
/*  display: none !important;*/
/*}*/
